@import url("https://fonts.googleapis.com/css?family=Montserrat");

/**********************/
/* Generic Components */
/**********************/

* {
  margin: 0px;
  padding: 0px;
  font-family: poppins;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}
ul {
  list-style: none;
}
button {
  color: white;
  background-color: #0f71f7;
  border: none;
  border-radius: 3px;
  padding: 5px 15px;
  font-size: 1.2em;
  cursor: pointer;
}
button:disabled {
  background-color: #84b5fa;
  color: #ccc;
  cursor: not-allowed;
}

input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 1.2em;
}

textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  width: 600px;
  height: 200px;
  font-size: 1.2em;
}

/* Login Page */

/* Dashboard */

/* Attendance */

/* Members */

/* Member Details Popup */
.table-tag {
  width: 200px;
  /* text-align: end; */
  font-weight: bold;
}

/* Events Page */
#events-list-container {
  overflow-y: scroll;
  height: 980px;
}

/* Scanner Page */

/**************/
/* Components */
/**************/

/*===NavBar=========================== */
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(236, 236, 236);
  padding: 15px 6%;
  width: 100%;
  margin: auto;
}
.logo {
  color: #181818;
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
}
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu li a {
  margin: 0px 5px;
  padding: 10px 15px;
  color: #1f1f1f;
  font-size: 1.2rem;
}
.nav-profile {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f5f5f5;
  padding: 5px;
}
.nav-profile img {
  height: 60%;
  object-fit: contain;
  object-position: center;
  height: 60%;
}
.menu-icon,
.menu-btn {
  display: none;
}
@media (max-width: 1600px) {
  .menu li a {
    font-size: 1rem;
  }
  .logo {
    font-size: 2rem;
  }
}
@media (max-width: 940px) {
  .navigation {
    padding: 15px;
  }
  .logo {
    font-size: 1.5rem;
  }
  .nav-profile {
    width: 40px;
    height: 40px;
  }
  .navigation .menu {
    display: none;
    position: absolute;
    top: 100px;
    left: 0px;
    background-color: #ffffff;
    border-bottom: 1px solid #dddddd;
    width: 100%;
    padding: 0px;
    margin: 0px;
    z-index: 100;
  }
  .navigation .menu li {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  .navigation .menu li a {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 30px 20px;
    border: 1px solid rgba(211, 211, 211, 0.01);
  }
  .navigation .menu li a:hover {
    color: #14171c;
    background-color: #e3e3e3;
    transition: 0.2s;
    opacity: 1;
  }
  .menu-icon {
    display: block;
  }
  .navigation .menu-icon {
    cursor: pointer;
    float: right;
    padding: 5px;
    position: relative;
    user-select: none;
    z-index: 2;
    cursor: pointer;
  }
  .navigation .menu-icon .nav-icon {
    background-color: #e3e3e3;
    display: block;
    position: relative;
    height: 2px;
    transition: background 0.2s ease-out;
    width: 25px;
  }
  .navigation .menu-icon .nav-icon:before,
  .navigation .menu-icon .nav-icon:after {
    background-color: #14171c;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all ease-out 0.2s;
    width: 100%;
  }
  .navigation .menu-icon .nav-icon:before {
    top: 8px;
  }
  .navigation .menu-icon .nav-icon:after {
    top: -8px;
  }
  .navigation .menu-icon .nav-icon,
  .navigation .menu-icon:checked ~ .menu-icon .nav-icon {
    background-color: #14171c;
  }

  .navigation .menu-btn:checked ~ .menu-icon .nav-icon {
    background-color: transparent !important;
  }
  .navigation .menu-btn:checked ~ .menu-icon .nav-icon:before {
    transform: rotate(-45deg);
    top: 0px;
  }
  .navigation .menu-btn:checked ~ .menu-icon .nav-icon:after {
    transform: rotate(45deg);
    top: 0px;
  }
  .navigation .menu-btn:checked ~ .menu {
    display: block;
  }
}
/*==Section============================*/

.dashboard-section {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
}
.Dashboard-all-total {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  width: 100%;
}
.dash-top-boxes {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.icon {
  height: 40px;
  width: 40px;
  object-fit: contain;
  object-position: center;
  margin-right: 6px;
  background-color: rgb(247, 247, 247, 0.3);
  padding: 5px;
  border-radius: 8px;
}
.dash-top-boxes h2 {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dash-top-boxes p,
.dash-current-house p {
  color: #ffffff;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 1px;
}
.dash-total-member {
  background: rgb(97, 29, 231);
  background: linear-gradient(
    83deg,
    rgb(52, 27, 114) 0%,
    rgba(164, 119, 254, 1) 100%
  );
}
.dash-member-attendance {
  background: rgb(43, 232, 128);
  background: linear-gradient(
    277deg,
    rgb(43, 232, 185) 0%,
    rgb(13, 155, 131) 100%
  );
}
.dash-week-event {
  background: rgb(43, 85, 232);
  background: linear-gradient(
    83deg,
    rgb(29, 52, 133) 0%,
    rgb(72, 147, 232) 100%
  );
}
.Dashboard-Main {
  border: 1px solid #ebebeb;
  margin-top: 30px;
  border-radius: 10px;
  padding: 20px;
}
.Dashboard-Main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 180px 180px 180px 180px;
  width: 100%;
  grid-gap: 20px;
}
.dash-add-member {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color: #222537;
  display: flex;
  align-items: center;
}
.dash-add-member img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: center;
  background-color: #344153;
  padding: 15px;
  border-radius: 50%;
  margin-left: 20px;
}
.dash-current-house {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: #ea7317;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dash-current-house img {
  height: 25px;
  background-color: #f69246;
  padding: 5px;
  border-radius: 4px;
  margin-right: 5px;
}
.dash-chart-1 {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
}
.dash-chart-2 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 5;
}
.dash-chart-g {
  height: 100%;
}
.dash-events {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 5;
}
.main-dash-box {
  border-radius: 10px;
  padding: 20px;
  height: 100%;
}
.main-dash-box h2 {
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dash-chart {
  padding: 20px;
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid #ebebeb;
}
.dash-events {
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  padding: 20px;
  overflow-y: auto;
}
.dash-events h3 {
  background-color: #0f71f7;
  color: #ffffff;
  padding: 4px 20px;
  border-radius: 20px;
  margin-bottom: 10px;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.2rem;
}
.dash-events h2 {
  color: #333333;
  font-size: 1.6rem;
}
.dash-events p {
  color: rgb(129, 129, 129);
  font-size: 1.2rem;
}
.dash-event-space {
  margin-bottom: 10px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgb(240, 240, 240);
}
@media (max-width: 1600px) {
  .dashboard-section {
    max-width: 1100px;
  }
  .Dashboard-Main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 120px 120px 120px 120px;
    width: 100%;
    grid-gap: 20px;
  }
  .dash-top-boxes img {
    height: 20px;
    object-fit: contain;
    object-position: center;
    margin-right: 6px;
    background-color: rgb(247, 247, 247, 0.3);
    padding: 4px;
    border-radius: 2px;
  }
  .dash-top-boxes h2 {
    color: #ffffff;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .dash-top-boxes p,
  .dash-current-house p {
    color: #ffffff;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .main-dash-box h2 {
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 1px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .dash-events h3 {
    background-color: #0f71f7;
    color: #ffffff;
    padding: 4px 10px;
    border-radius: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 0.9rem;
  }
  .dash-events h2 {
    color: #333333;
    font-size: 1.2rem;
  }
  .dash-events p {
    color: rgb(129, 129, 129);
    font-size: 0.9rem;
  }
}
@media (max-width: 900px) {
  .Dashboard-all-total {
    grid-template-columns: 1fr 1fr;
  }

  .Dashboard-Main {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 120px 120px 120px 120px;
    grid-auto-rows: 120px;
    width: 100%;
  }

  .dash-add-member {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .dash-current-house {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .dash-chart-1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;
  }
  .dash-chart-2 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 6;
  }

  .dash-events {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 6;
    grid-row-end: 8;
  }
}
@media (max-width: 600px) {
  .Dashboard-all-total {
    grid-template-columns: 1fr;
  }

  .Dashboard-Main {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    padding: 10px;
  }
  .dash-top-boxes p,
  .dash-current-house p {
    font-size: 1.4rem;
  }
  .Dashboard-all-total {
    grid-gap: 10px;
  }
  .dash-add-member img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    padding: 8px;
  }
  .dash-chart-g {
    min-height: 250px;
  }
  .dash-current-house h2,
  .dash-top-boxes h2 {
    flex-wrap: wrap;
  }
}
/*==Attendence==============================*/
.attendance {
  display: grid;
  grid-template-columns: 0.6fr 1.4fr;
  width: 80%;
  margin: 50px auto;
  align-items: flex-start;
}
.attendance-allmembers,
.attendance-member-name {
  border: 1px solid rgb(226, 226, 226);
  border-radius: 3px;
  padding: 20px;
}
.attendance-allmembers {
  margin-right: 20px;
}
.attendance-member-name,
.attendance-allmembers {
  height: 66vh;
  overflow: hidden;
  overflow-y: auto;
}
.attendance-member-container {
  width: 100%;
}
.attendance-allmembers-heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.attendance-allmembers-heading h3 {
  color: #1f1f1f;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.attendance-allmembers-heading input,
.attendance-member-name .member-name-h1 input, .attendance-member-name .member-name-h2 input {
  background-color: #f5f5f5;
  padding: 10px;
  border: none;
  outline: none;
  height: 40px;
  width: 90%;
  font-size: 0.9rem;
}

.attendance-member-name .member-name-h2 input {
  width: 27%;
}
.attendance-allmembers table {
  margin-top: 20px;
}

.attendance-member-name .member-name {
  font-size: 1.2rem;
}
.attendance-allmembers td {
  color: #2f2f2f;
  font-size: 1.2rem;
  width: 100%;
  padding: 10px 0px;
}
.attendance-allmembers td button {
  width: 100px;
  height: 35px;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-left: auto;
}
.attendance-member-name .member-name-h1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.member-name-h th,
.attendance-allmembers-heading h3 {
  font-size: 1.4rem;
}
.attendance-member-name .member-name {
  color: #1b1b1b;
  font-weight: 500;
}
.attendance-member-name .member-check-time {
  color: #333333;
  text-align: center;
  font-size: 1.14rem;
}
.attendance-member-name tr {
  margin: 10px;
}
.attendance-member-container form {
  color: #2f2f2f;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.attendance-member-container form input {
  background-color: #f1f1f1;
  border: none;
  outline: none;
  font-size: 1rem;
  text-transform: uppercase;
}
.attendance-member-name table {
  max-width: 100%;
  width: 100%;
}
.attendance-member-container form button {
  margin-left: 10px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.refreshLogsButton{
  margin-left: 10px;
  color:#0f71f7;
  cursor: pointer;
}

.refreshLogsButtonDisabled{
  margin-left: 10px;
  color: #2f2f2f;
}

@media (max-width: 1600px) {
  .attendance {
    display: grid;
    grid-template-columns: 0.6fr 1.4fr;
    max-width: 1100px;
    width: 90%;
    margin: 50px auto;
    align-items: flex-start;
  }
  .attendance-member-name .member-check-time {
    font-size: 1rem;
  }
  .attendance-member-name .member-name {
    font-size: 1rem;
  }
  .member-name-h th,
  .attendance-allmembers-heading h3,
  .attendance-allmembers td {
    font-size: 1rem;
  }
}
@media (max-width: 880px) {
  .attendance {
    display: grid;
    grid-template-columns: 1fr;
  }
  .attendance-member-name,
  .attendance-allmembers {
    max-height: 450px;
    overflow-y: auto !important;
  }
  .attendance-allmembers {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .attendance-allmembers-heading input {
    width: 100%;
  }
}
@media (max-width: 570px) {
  .attendance-member-name {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
  }
  .attendance-allmembers {
    width: 100%;
    max-width: 100%;
  }
  .attendance-member-name table {
    min-width: 500px;
    overflow-x: scroll;
  }
  .attendance-member-container form {
    flex-wrap: wrap;
  }
  .attendance {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .attendance-member-container form {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .attendance-member-container form button {
    margin-top: 10px;
    height: 45px;
    max-width: 180px;
    width: 100%;
    margin-left: 0px;
  }
}
/*==Members======================*/
.member-main {
  height: 88vh;
}

.members-section {
  width: 80%;
  margin: 50px auto 0px auto;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 10px 20px;
  height: 94%;
  overflow: hidden;
  overflow: auto;
}
.member-section-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.member-buttons button {
  color: #ffffff;
  margin-right: 5px;
  border-radius: 4px;
}
.member-section-top input {
  background-color: #f6f6f6;
  border: 1px solid rgb(238, 238, 238);
  outline: none;
  font-size: 1rem;
  max-width: 340px;
  width: 100%;
}
.members-section table {
  margin-top: 20px;
  width: 100%;
}
.members-section table th {
  text-align: start;
  background-color: #f5f5f5;
  padding: 5px 10px;
  font-size: 1.2rem;
}
.members-section table td {
  padding: 5px 10px;
}
.members-section table th,
.members-section table td,
.member-buttons button,
.member-section-top input {
  font-size: 1.2rem;
}

.pagination {
  float: right;
  margin-right: 10%;
  /* width: 70%; */
}

#pagination-break {
  border: none;
  border-radius: 3px;
  padding: 5px 15px;
  font-size: 1.2em;
  background-color: #f5f5f5;
}

.export-button {
  float: left;
  margin-left: 10%;
  margin-top: 4px;
}

.page-link {
  margin: 4px;
  width: 50px;
}

.add-member-form,
.member-details-popup,
.member-id-qr-popup,
.event-form,
.event-form-details {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: auto;
}

.popup-inner {
  max-width: 640px !important;
  width: 90%;
}
.add-member-form h1,
.member-details-popup h1,
.member-draft-message h1,
.event-form h1,
.event-form-details h1 {
  color: #2f2f2f;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #18417a;
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 4px;
  letter-spacing: 1px;
}

.add-member-form form h2,
.member-details-popup h2,
.event-form form h2,
.event-form-details h2 {
  color: #1b1b1b;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 20px;
}
.add-member-form form hr,
.event-form form hr {
  border: 1px solid #ffffff;
  opacity: 0;
  max-width: 120px;
  width: 100%;
  margin-bottom: 0px;
}
.member-presonal-info {
  display: flex;
  flex-direction: column;
}
.member-presonal-info input,
.add-member-form form input,
.member-draft-message input,
.event-form form input {
  background-color: rgb(245, 245, 245);
  border: none;
  outline: none;
  margin-bottom: 6px;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #dfdfdf;
}
.member-gender-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.member-gender-form label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}
.member-gender-form label input[type="radio"] {
  display: flex;
  width: 15px;
  height: 15px;
  margin: 0px;
}
.add-member-form form button {
  background-color: #2158ee;
  color: #ffffff;
  width: 100%;
  margin-top: 20px;
  font-size: 1rem;
  height: 45px;
  border-radius: 5px;
}
.member-details-popup table,
.event-form-details table {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid rgb(221, 221, 221);
}
.member-details-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.member-details-edit-btns,
.event-details-edit-btns {
  margin-left: 10px;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap;
}
.member-details-edit-btns .member-edit-icon {
  background-color: rgb(233, 233, 233);
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid rgb(221, 221, 221);
  cursor: pointer;
}

.member-id-img {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.member-id-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 10px;
}
.member-id-img button {
  margin-right: 10px;
}
.member-camera {
  width: 100%;
  height: 100%;
  padding: 5px;
  object-fit: contain;
}
.printable {
  width: 100%;
}
.memberid-qr-cover {
  width: 100%;
  height: 100%;
  margin: 20px 0px 0px 0px;
}
.memberid-qr-cover img {
  max-height: 150px;
  object-fit: cover;
  object-position: center;
}

/* Member ID Styles */

.printable {
  background: rgb(85, 85, 85);
  background: linear-gradient(
    180deg,
    rgba(85, 85, 85, 1) 60%,
    rgba(255, 255, 255, 1) 60%
  );
  height: 337px;
  display: flex;
}
.memberid-banner-container {
  display: flex;
  margin-left: 10px;
}

.memberid-banner {
  width: 10px;
  height: 100%;
}
.memberid-profile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 90%;
  flex-wrap: wrap;
  margin-left: -10px;
}
.memberid-qr-profile {
  margin-top: -30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 220px;
  top: 20px;
}

.memberid-qr-profile h1 {
  font-size: 1.1rem;
  margin-top: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}
.memberid-qr-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  margin-top: 5px;
  margin-left: -50px;
}
.memberid-qr-container h3 {
  font-size: 0.9rem;
  color: #1f1f1f;
  font-weight: 500;
  letter-spacing: 2px;
}
.memberid-qr-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}
.memberid-qr-btns button {
  margin: 0px 5px;
}
.member-draft-message {
  max-height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.member-draft-message textarea,
.event-form form textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  outline: none;
  background-color: #f0f0f0;
}
.member-draft-message textarea::placeholder {
  font-size: 1rem;
}
.member-draft-message button,
.event-form form button {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  font-size: 1rem;
}
.member-draft-message h1 {
  margin-bottom: 20px;
}
.member-draft-message h2 {
  font-size: 1.2rem;
  color: #1f1f1f;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
.member-draft-message table {
  margin-top: 10px;
  background-color: #f1f1f1;
  width: 100%;
}
.member-draft-message table tr td {
  padding: 5px 10px;
  margin: 0px !important;
  border: 1px solid rgb(228, 228, 228);
}
.member-draft-message .draft-name {
  margin-right: 10px;
  display: flex;
}
@media (max-width: 1600px) {
  .members-section {
    width: 90%;
    max-width: 1100px;
  }

  .members-section table th,
  .members-section table td,
  .member-buttons button,
  .member-section-top input {
    font-size: 1rem;
  }
}
@media (max-width: 770px) {
  .member-section-top {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .member-section-top input {
    margin-top: 10px;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 600px) {
  .members-section {
    overflow: auto;
  }
  .members-section {
    max-height: 100%;
  }
  .members-section table {
    min-width: 600px;
    overflow-x: scroll;
  }
  .add-member-form form h2,
  .member-details-popup h2,
  .event-form form h2,
  .event-form-details h2 {
    color: #1b1b1b;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 20px;
  }
  .popup-inner {
    padding: 20px 10px;
  }
  .memberid-qr-profile {
    max-width: 220px;
    width: 90%;
  }
  .memberid-qr-btns {
    flex-wrap: wrap;
  }
  .memberid-qr-btns button {
    margin-top: 3px;
  }
  .memberid-profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 380px) {
  .member-buttons {
    flex-direction: column;
  }
  .member-buttons button {
    width: 100%;
    margin: 3px 0px;
  }
  .member-details-buttons {
    flex-wrap: wrap;
    padding: 0px;
  }

  .member-details-edit-btns,
  .event-details-edit-btns {
    margin-left: 0px !important;
    margin-right: 5px;
    margin-top: 10px;
  }
  .member-id-img button {
    margin-top: 3px;
  }
  .memberid-qr-btns button {
    width: 100%;
  }
}
@media (max-width: 340px) {
  .member-draft-message table {
    width: 100%;
  }
  .member-draft-message table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
  }
  .member-draft-message table tr td {
    width: 100%;
    margin: 2px 0px;
  }
}
/*==Event======================================*/
.event-section {
  display: flex;
  margin: 50px auto;
  width: 80%;
}
.event-container {
  width: 100%;
  margin-right: 20px;
}

.events-list-container {
  width: 100%;
}
.event-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  background-color: #ffffff;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.07);
}
.event-heading h1 {
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  color: #131313;
}
.event-heading button {
  font-size: 1rem;
  height: 45px;
}
.events-list-container h3 {
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 1px;
  color: #2f2f2f;
  margin-top: 30px;
  background-color: #ffffff;
  padding: 3px 10px;
}
.events-list-container hr {
  display: flex;
  border: 1px solid #ffffff;
  border-top: 1px solid #c5c5c5;
}
.event-box {
  border: 1px solid rgb(207, 207, 207);
  border-radius: 5px;
  margin: 10px 0px;
  padding: 20px;
  background-color: #f5f5f5;
}
.event-box h2 {
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.4rem;
}
.event-box h4 {
  font-size: 0.9rem;
  font-weight: 500;
  color: #1d41e2;
}
.event-box button {
  margin-top: 15px;
  font-size: 1rem;
}

.event-calendar p {
  color: #1f1f1f;
  text-align: center;
  margin-top: 10px;
}
.event-cal {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid rgb(207, 207, 207);
}
.react-calendar__navigation__label__labelText {
  color: #14171c;
}
.react-calendar__navigation button {
  color: #131313;
}
.react-calendar__month-view__days__day {
  color: #14171c;
}
.react-calendar__month-view__days__day--weekend {
  color: #0f71f7;
}
.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.5;
}
.react-calendar__tile--active {
  color: #ffffff;
}
.event-form form {
  width: 100%;
}
.event-form-gender {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
}
.event-form-gender label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  font-size: 1.2rem;
  color: #1f1f1f;
  user-select: none;
}
.event-form-gender input[type="checkbox"] {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}
.event-details-edit-btns {
  margin-top: 20px;
}
.event-details-edit-btns .event-edit-btn-icon {
  padding: 10px;
  background-color: #0f71f7;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}
@media (max-width: 1600px) {
  .event-section {
    display: flex;
    max-width: 1100px;
    width: 90%;
  }
}
@media (max-width: 960px) {
  .event-section {
    display: flex;
    flex-direction: column;
  }
  .event-calendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    text-align: center;
  }
  .event-heading {
    flex-wrap: wrap;
  }
}
@media (max-width: 540px) {
  .event-heading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .event-heading h1 {
    font-size: 1.3rem;
  }
  .events-list-container h2 {
    font-size: 1.2rem;
  }
  .event-heading button {
    margin-top: 8px;
    width: 100%;
  }
}
@media (max-width: 400px) {
  .add-member-form,
  .member-details-popup,
  .member-id-qr-popup,
  .event-form,
  .event-form-details {
    padding: 20px 8px;
  }
}
@media (max-width: 300px) {
  .event-form-gender {
    flex-wrap: wrap;
  }
}
/*==Login==========================*/
#login-failure {
  text-align: center;
  padding: 15px;
  background: #e57373;
  color: white;
  border-radius: 4px;
}

.login-section {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  overflow: auto;
  z-index: 100;
}
.login-container {
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  background-color: #ffffff;

  margin: auto;
}
.login-container form h1 {
  font-size: 1.6rem;
  text-align: center;
  border-radius: 30px;
  margin-bottom: 10px;
  font-weight: 600;
  letter-spacing: 1px;
}
.login-container form input {
  width: 100%;
  height: 45px;
  margin: 5px 0px;
  background-color: #f3f3f3;
  border: 1px solid #eeeeee;
  font-size: 1rem;
  outline: none;
}
.login-container form button {
  width: 100%;
  height: 45px;
  margin-top: 5px;
  font-size: 1rem;
  text-transform: uppercase;
}
.login-bg {
  width: 100%;
  height: 100%;
  background: rgb(0, 97, 230);
  background: linear-gradient(
    0deg,
    rgba(0, 97, 230, 1) 0%,
    rgba(109, 172, 255, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.login-bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
@media (max-width: 850px) {
  .login-section {
    grid-template-columns: 1.3fr 1fr;
  }
}
@media (max-width: 670px) {
  .login-section {
    grid-template-columns: 1fr;
  }
  .login-bg {
    display: none;
  }
  .login-container form h1 {
    font-size: 1.2rem;
  }
}
/*==Scanner==============================*/
.scanner-section {
  max-width: 600px;
  width: 90%;
  margin: 50px auto;
}
.scanner-section form {
  width: 100%;
  display: flex;
  align-items: center;
}
.scanner-section form input {
  width: 100%;
  margin-right: 10px;
  background-color: #eeeeee;
}
.scanner-section form button {
  font-size: 1rem;
  padding: 10px 20px;
  height: 42px;
  letter-spacing: 1px;
}
.scanner-section table {
  width: 100%;
  border: 1px solid rgb(243, 243, 243);
  margin-top: 10px;
  background-color: #f1f1f1;
  border-radius: 0px 0px 10px 10px;
}
.scanner-section table tr {
  width: 100%;
}
.scanner-section table tr td {
  padding: 5px;
  border: 1px solid rgb(228, 228, 228);
}
